import "./style.css";
import { style } from "../category/SubCategoryCss";
import {
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { useMediaQuery } from "react-responsive";

import { getRequest } from "../../../helper/AxiosClient";
import CompanyModal from "./CompanyModal";

const Payment = () => {
  const [sortConfig, setSortConfig] = useState({ field: "", direction: "asc" });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const [users, setUsers] = useState([]);

  const [paymentData, setPaymentData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [paymentLoading, setPaymentLoading] = useState(false);

  const [withdrawModal, setWithdrawModal] = useState({
    open: false,
    data: "",
  });

  // Table Columns
  const tableColumns = [
    { key: "transactionId", label: "Transaction ID" },
    { key: "senderEmail", label: "Sender Email" },
    { key: "receiverEmail", label: "Receiver Email" },
    { key: "amount", label: "Amount" },
    { key: "currency", label: "Currency" },
  ];

  // Handle Sort
  const handleSort = (field) => {
    const isAsc = sortConfig.field === field && sortConfig.direction === "asc";
    setSortConfig({ field, direction: isAsc ? "desc" : "asc" });
  };

  // Local Sorting
  const sortedItems = useMemo(() => {
    let sortableItems = [...users];
    if (sortConfig.field !== "") {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.field] < b[sortConfig.field]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.field] > b[sortConfig.field]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [users, sortConfig]);

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLaptopLarge = useMediaQuery({ minWidth: 1599 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });

  const itemsPerPage = isLargeScreenLaptop
    ? 8
    : isLaptopLarge
    ? 8
    : isLaptopMedium
    ? 8
    : isLaptop
    ? 6
    : 10;

  // Handle Page Click
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  // Get Pre Users
  const getTransactionHistory = async (page = 1) => {
    setLoading(true);
    let payload = {
      app_id: 2,
      page: page,
      limit: itemsPerPage,
    };
    try {
      const res = await getRequest("admin/transaction-history", payload);
      setUsers(res.data.data.docs);
      setTotalUsers(res.data.data.totalDocs);
      setTotalPages(res.data.data.totalPages);
      setLoading(false);
    } catch (error) {
      setUsers([]);
      setLoading(false);
    }
  };

  // Get Pre Users
  const getPayment = async (page = 1) => {
    setPaymentLoading(true);
    try {
      const res = await getRequest("admin/payment-calculation");
      setPaymentLoading(false);
      setPaymentData(res?.data?.data);
    } catch (error) {
      setPaymentData([]);
      setPaymentLoading(false);
    }
  };

  // Use Effects
  useEffect(() => {
    getTransactionHistory(currentPage);
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    getPayment();
  }, []);

  return (
    <>
      <div className="row px-3">
        <div className="col-3 payment_box_shadow p-4 me-3">
          {paymentLoading ? (
            <Skeleton animation="wave" sx={{ width: "100%", height: "15vh" }} />
          ) : (
            <>
              <h5 className="fw-bold">Escrow Amount</h5>
              <div className="d-flex align-items-center justify-content-between gap-3">
                <div>
                  <h5 className="text-gray">USD</h5>
                  <h4 className="fw-semibold">
                    {paymentData?.escrowAmountUSD?.toFixed(2)}
                  </h4>
                </div>
                <div>
                  <h5 className="text-gray">CAD</h5>
                  <h4 className="fw-semibold">
                    {paymentData?.escrowAmountCAD?.toFixed(2)}
                  </h4>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="col-3 payment_box_shadow p-4 me-3">
          {paymentLoading ? (
            <Skeleton animation="wave" sx={{ width: "100%", height: "15vh" }} />
          ) : (
            <>
              <h5 className="fw-bold">Seller Amount</h5>
              <div className="d-flex align-items-center justify-content-between gap-3">
                <div>
                  <h5 className="text-gray">USD:</h5>
                  <h4 className="fw-semibold">
                    {paymentData?.sellersAmountUSD?.toFixed(2)}
                  </h4>
                </div>
                <div>
                  <h5 className="text-gray">CAD:</h5>
                  <h4 className="fw-semibold">
                    {paymentData?.sellersAmountCAD?.toFixed(2)}
                  </h4>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="col-3 payment_box_shadow p-4">
          {paymentLoading ? (
            <Skeleton animation="wave" sx={{ width: "100%", height: "15vh" }} />
          ) : (
            <>
              <h5 className="fw-bold">Company Amount</h5>
              <div className="d-flex align-items-center justify-content-between gap-3">
                <div>
                  <h5 className="text-gray">USD:</h5>
                  <h4 className="fw-semibold">
                    {paymentData?.companyAmountUSD?.toFixed(2)}
                  </h4>
                  <small
                    className="text-decoration-underline cursor-pointer"
                    onClick={() =>
                      setWithdrawModal({
                        open: true,
                        data: "USD",
                      })
                    }
                  >
                    Withdraw
                  </small>
                </div>
                <div>
                  <h5 className="text-gray">CAD:</h5>
                  <h4 className="fw-semibold">
                    {paymentData?.companyAmountCAD?.toFixed(2)}
                  </h4>
                  <small
                    className="text-decoration-underline cursor-pointer"
                    onClick={() =>
                      setWithdrawModal({
                        open: true,
                        data: "CAD",
                      })
                    }
                  >
                    Withdraw
                  </small>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Paper variant="outlined" className="mt-3">
        <div className="d-flex justify-content-between align-items-center p-4">
          <h5 className="fw-semibold">
            Transaction History Table ({totalUsers}){" "}
            <span
              className="cursor-pointer"
              onClick={() => getTransactionHistory(currentPage)}
            >
              <RefreshIcon />
            </span>{" "}
          </h5>
        </div>
        <TableContainer sx={style.categoryBoxStyle} className="tableScrollNone">
          <Table stickyHeader={true} aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableColumns?.map((column) => (
                  <TableCell key={column.key} sx={style.tableHeaderTextColor}>
                    <IconButton
                      onClick={() => handleSort(column.key)}
                      sx={{ borderRadius: "0", padding: 0 }}
                    >
                      <TableSortLabel
                        active={sortConfig.field === column.key}
                        direction={
                          sortConfig.field === column.key
                            ? sortConfig.direction
                            : "asc"
                        }
                        sx={style.tableHeaderTextColor}
                      >
                        {column.label}
                      </TableSortLabel>
                    </IconButton>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ overflow: "hidden" }}>
              {loading
                ? Array.from(new Array(itemsPerPage)).map((_, index) => (
                    <TableRow key={index}>
                      {Array.from(new Array(5)).map((_, colIndex) => (
                        <TableCell key={colIndex}>
                          <Skeleton
                            animation="wave"
                            sx={{ width: "100%", height: "20px" }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : sortedItems.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={style.categoryFontStyle}>
                        {item.transactionId}
                      </TableCell>
                      <TableCell sx={style.categoryFontStyle}>
                        {item.senderEmail}
                      </TableCell>
                      <TableCell sx={style.categoryFontStyle}>
                        {item.receiverEmail}
                      </TableCell>
                      <TableCell>{item.amount}</TableCell>
                      <TableCell>{item.currency}</TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReactPaginate
            nextClassName={"next_previous_classname"}
            previousClassName={"next_previous_classname"}
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageClassName={"pagi-item"}
            pageLinkClassName={"pagi-link pagination-link"}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            containerClassName={"pagination pagination-border-radius"}
            subContainerClassName={"pages pagination"}
            activeClassName={"page-active"}
            // initialPage={0}
            pageCount={totalPages}
            onPageChange={handlePageClick}
            forcePage={currentPage - 1}
          />
        </div>
      </Paper>

      <CompanyModal
        open={withdrawModal}
        close={() => {
          setWithdrawModal({ open: false, data: "" });
        }}
        refrehListing={() => getPayment()}
      />
    </>
  );
};

export default Payment;
