import {
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { style } from "../category/SubCategoryCss";
import { useEffect, useMemo, useState } from "react";
import EmailInvitationModal from "./EmailInvitationModal";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../helper/AxiosClient";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";

import ReactPaginate from "react-paginate";
import { useMediaQuery } from "react-responsive";
import ConfirmationDeleteModal from "../../comman/component/ConfirmationDeleteModal";

const PreSignupUsers = () => {
  // Use States
  const [sortConfig, setSortConfig] = useState({ field: "", direction: "asc" });
  const [modalOpen, setModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);
  console.log(
    "🚀 ~ file: PreSignupUsers.jsx:45 ~ PreSignupUsers ~ selectedUser:",
    selectedUser
  );
  const [btnLoading, setBtnLoading] = useState(false);

  // Handle Page Click
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  // Handle Sort
  const handleSort = (field) => {
    const isAsc = sortConfig.field === field && sortConfig.direction === "asc";
    setSortConfig({ field, direction: isAsc ? "desc" : "asc" });
  };

  // Local Sorting
  const sortedItems = useMemo(() => {
    let sortableItems = [...users];
    if (sortConfig.field !== "") {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.field] < b[sortConfig.field]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.field] > b[sortConfig.field]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [users, sortConfig]);

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLaptopLarge = useMediaQuery({ minWidth: 1599 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });

  const itemsPerPage = isLargeScreenLaptop
    ? 9
    : isLaptopLarge
    ? 9
    : isLaptopMedium
    ? 9
    : isLaptop
    ? 6
    : 10;

  // Table Columns
  const tableColumns = [
    { key: "username", label: "Name" },
    { key: "email", label: "Email Address" },
    { key: "accountType", label: "Account Type" },
    { key: "passwordRequest", label: "Email Invitation" },
    { key: "", label: "Action" },
  ];

  // Get Pre Users
  const preSignupUsers = async (page = 1) => {
    setLoading(true);
    let payload = {
      app_id: 2,
      page: page,
      limit: itemsPerPage,
    };
    try {
      const res = await getRequest("admin/get-pre-register-users", payload);
      setUsers(res.data.data.docs);
      setTotalUsers(res.data.data.totalDocs);
      setTotalPages(res.data.data.totalPages);
      setLoading(false);
    } catch (error) {
      setUsers([]);
      setLoading(false);
    }
  };

  // Handle Delete
  const handleDelete = async () => {
    setBtnLoading(true);

    let payload = {
      user_id: selectedUser?.id,
      app_id: 2,
    };

    try {
      const res = await postRequest("admin/delete-user", payload);

      if (res.response.status === 200) {
        setDeleteModal(false);
        setBtnLoading(false);
        preSignupUsers(currentPage);
      }
    } catch (error) {
      setDeleteModal(false);
      setBtnLoading(false);
    }
  };

  // Use Effects
  useEffect(() => {
    preSignupUsers(currentPage);
  }, [currentPage, itemsPerPage]);

  return (
    <>
      <Paper variant="outlined">
        <div className="d-flex justify-content-between align-items-center p-4">
          <h6 className="fw-semibold">
            Pre SignUp Users ({totalUsers}){" "}
            <span
              className="cursor-pointer"
              onClick={() => {
                setCurrentPage(1);
                preSignupUsers(0);
              }}
            >
              <RefreshIcon />
            </span>{" "}
          </h6>
          <button
            className="btn btn-success"
            onClick={() => setModalOpen(true)}
          >
            Send Email Invitation
          </button>
        </div>
        <TableContainer sx={style.categoryBoxStyle} className="tableScrollNone">
          <Table stickyHeader={true} aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableColumns?.map((column) => (
                  <TableCell key={column.key} sx={style.tableHeaderTextColor}>
                    <IconButton
                      onClick={() => handleSort(column.key)}
                      sx={{ borderRadius: "0", padding: 0 }}
                    >
                      <TableSortLabel
                        active={sortConfig.field === column.key}
                        direction={
                          sortConfig.field === column.key
                            ? sortConfig.direction
                            : "asc"
                        }
                        sx={style.tableHeaderTextColor}
                      >
                        {column.label}
                      </TableSortLabel>
                    </IconButton>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ overflow: "hidden" }}>
              {loading
                ? Array.from(new Array(itemsPerPage)).map((_, index) => (
                    <TableRow key={index}>
                      {Array.from(new Array(5)).map((_, colIndex) => (
                        <TableCell key={colIndex}>
                          <Skeleton
                            animation="wave"
                            sx={{ width: "100%", height: "20px" }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : sortedItems.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={style.categoryFontStyle}>
                        {item.username}
                      </TableCell>
                      <TableCell sx={style.categoryFontStyle}>
                        {item.email}
                      </TableCell>
                      <TableCell sx={style.categoryFontStyle}>
                        {item.accountType}
                      </TableCell>
                      <TableCell>
                        {item.passwordRequest === true ? (
                          <Typography color="green">Sent</Typography>
                        ) : (
                          <Typography color="red">Pending</Typography>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "16px", cursor: "pointer" }}
                        onClick={() => {
                          setDeleteModal(true);
                          setSelectedUser(item);
                        }}
                      >
                        <DeleteIcon sx={{ color: "red" }} />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReactPaginate
            nextClassName={"next_previous_classname"}
            previousClassName={"next_previous_classname"}
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageClassName={"pagi-item"}
            pageLinkClassName={"pagi-link pagination-link"}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            containerClassName={"pagination pagination-border-radius"}
            subContainerClassName={"pages pagination"}
            activeClassName={"page-active"}
            // initialPage={0}
            pageCount={totalPages}
            onPageChange={handlePageClick}
            forcePage={currentPage - 1}
          />
        </div>
      </Paper>

      <EmailInvitationModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        getpreSignupUsers={preSignupUsers}
      />

      <ConfirmationDeleteModal
        handleDeleteCloseConfirmModal={() => setDeleteModal(false)}
        deleteModal={deleteModal}
        handleDeleteApiCall={handleDelete}
        text={`Are you sure you want to delete this user?`}
        submitLoading={btnLoading}
      />
    </>
  );
};

export default PreSignupUsers;
