import { Route, Routes, Navigate } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
import Login from "./components/auth/login/Login";
import ContentLayout from "./components/comman/ContentLayout";
import { Layout } from "./components/comman/Layout";
import PostCategory from "./components/pages/postCategory/PostCategory";
import { ToastContainer } from "react-toastify";
import PreferedLanguages from "./components/pages/preferedLanguages/PreferedLanguages";
import ProfileTypes from "./components/pages/profileTypes/ProfileTypes";
import CategoryTwo from "./components/pages/category/CategoryTwo";
import SubCategory from "./components/pages/category/SubCategory";
import AppMulti from "./components/pages/app/AppsMulti";
import AddUser from "./components/pages/addUser/AddUser";
import Users from "./components/pages/users/Users";
import AppsUsers from "./components/pages/users/AppsUsers";
import UserProfile from "./components/pages/users/UserProfile";
import { ThemeProvider, createTheme } from "@mui/material";
import Register from "./components/auth/register/Register";
import { useGlobalContext } from "./context/GlobalContext";
import PreSignupUsers from "./components/pages/preSignupUsers/PreSignupUsers";
import Payment from "./components/pages/Payment/Payment";

const theme = createTheme();

function App() {
  const { isLogin } = useGlobalContext();
  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          {!isLogin ? (
            <>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<Login />} />
              {/* <Route path="/register" element={<Register />} /> */}
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          ) : (
            <>
              <Route element={<Layout hidePaths="/" />}>
                <Route element={<ContentLayout />}>
                  <Route path="/apps" element={<AppMulti />} />
                  <Route path="/apps/:id" element={<CategoryTwo />} />
                  <Route path="/apps/:id/:subId" element={<SubCategory />} />
                  <Route path="/postCategory" element={<PostCategory />} />
                  <Route path="/subAdmin" element={<AddUser />} />
                  <Route
                    path="/preferedLanguages"
                    element={<PreferedLanguages />}
                  />

                  <Route path="/fit" to="/go" link={true} />

                  <Route path="/profileTypes" element={<ProfileTypes />} />
                  <Route path="/appsUsers" element={<AppsUsers />} />
                  <Route path="/appsUsers/:appUsersId" element={<Users />} />
                  <Route
                    path="/appsUsers/:appUsersId/:userId"
                    element={<UserProfile />}
                  />
                  <Route
                    path="/pre-signup-users"
                    element={<PreSignupUsers />}
                  />
                  <Route path="/payments" element={<Payment />} />
                </Route>
                <Route path="*" element={<Navigate to="/apps" />} />
              </Route>
            </>
          )}
        </Routes>
        <ToastContainer />
      </ThemeProvider>
    </>
  );
}

export default App;
