import axios from "axios";
import { errorNotification } from "./notification";
// export const baseURL = "http://192.168.3.4:3000/api/v1/";
export const baseURL = "https://dev.api.ship2mates.com/v1/"; // development Server
// export const baseURL = "https://api.ship2mates.com/v1/"; // Production Server
const axiosClient = axios.create();
axiosClient.defaults.baseURL = baseURL;
axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem("current_user") != null
        ? JSON.parse(localStorage.getItem("current_user"))
        : null;
  } catch (error) {
    user = null;
  }
  return user;
};

export function unAuthorized(navigate) {
  localStorage.clear();
  navigate("/");
}

export function getRequest(URL, params, navigate) {
  axiosClient.defaults.params = params;
  axiosClient.defaults.headers.Authorization = `Bearer ${
    getCurrentUser()?.token
  }`;
  return axiosClient
    .get(`/${URL}`)
    .then((response) => response)
    .catch((error) => {
      if (error.response.status === 401) {
        errorNotification(error?.response?.data?.message);
        unAuthorized(navigate);
      }
      const errors = error?.message;
      const errormessage = error?.response?.data?.message;
      return { type: 2, errors, errormessage };
    });
}

export function postRequest(URL, payload, navigate) {
  axiosClient.defaults.headers.Authorization = `Bearer ${
    getCurrentUser()?.token
  }`;
  return axiosClient
    .post(`/${URL}`, payload)
    .then((response) => {
      return { type: 1, response };
    })
    .catch((error) => {
      if (error.response.status === 401) {
        errorNotification(error?.response?.data?.message);
        unAuthorized(navigate);
      }
      const errors = error?.message;
      const errormessage = error?.response?.data?.message;
      return { type: 2, errors, errormessage };
    });
}

export function putRequest(URL, payload, navigate) {
  axiosClient.defaults.headers.Authorization = `Bearer ${
    getCurrentUser()?.token
  }`;
  return axiosClient
    .put(`/${URL}`, payload)
    .then((response) => {
      return { type: 1, response };
    })
    .catch((error) => {
      if (error.response.status === 401) {
        errorNotification(error?.response?.data?.message);
        unAuthorized(navigate);
      }
      const errors = error?.message;
      const errormessage = error?.response?.data?.message;
      return { type: 2, errors, errormessage };
    });
}

export function patchRequest(URL, payload) {
  axiosClient.defaults.headers.Authorization = `Bearer ${
    getCurrentUser()?.token
  }`;
  return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL, navigate) {
  axiosClient.defaults.headers.Authorization = `Bearer ${
    getCurrentUser()?.token
  }`;
  return axiosClient
    .delete(`/${URL}`)
    .then((response) => {
      return { type: 1, response };
    })
    .catch((error) => {
      if (error.response.status === 401) {
        errorNotification(error?.response?.data?.message);
        unAuthorized(navigate);
      }
      const errors = error?.message;
      const errormessage = error?.response?.data?.message;
      return { type: 2, errors, errormessage };
    });
}
