import React, { useEffect, useState } from "react";
import { style } from "./SubCategoryCss";
import {
  Box,
  Paper,
  Typography,
  Button,
  Divider,
  Grid,
  Modal,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  baseURL,
  getCurrentUser,
  getRequest,
} from "../../../helper/AxiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import backArrow from "../../../assets/images/arrow-left.png";
import editIcon from "../../../assets/images/edit-2.svg";
import deleteIcon from "../../../assets/images/trash.png";
import { RiCloseFill } from "react-icons/ri";
import InputField from "../../comman/component/CustomInput";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ConfirmationDeleteModal from "../../comman/component/ConfirmationDeleteModal";
import Papa from "papaparse";
import { AiOutlineClose } from "react-icons/ai";

const SubCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { subId } = useParams();
  const { id } = useParams();
  const [csvFile, setCsvFile] = useState();
  const [categoryName, setCategoryName] = useState("");
  const [editedId, setEditedId] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [csvModal, setCsvModal] = useState(false);
  const [deleteModalTableId, setDeleteModalTableId] = useState("");
  const [modalSubCategoryName, setModalSubCategoryName] = useState("");
  const [icon, setIcon] = useState("");
  const [categoryNameData, setCategoryNameData] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const subCategoryTitle = location?.state?.data?.category;

  // get sub category data

  const getSubCategoryData = async () => {
    setLoading(true);
    try {
      const res = await getRequest(
        `admin/get-subcategory-list?app_id=${id}&id=${subId}`
      );
      setLoading(false);
      setCategoryNameData(res.data.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getSubCategoryData();
  }, []);

  // delete sub category

  const handleDeleteSubCategory = async () => {
    setSubmitLoading(true);
    const payload = {
      subCategoryId: deleteModalTableId,
      categoryId: subId,
      app_id: id,
    };
    try {
      await axios.delete(`${baseURL}admin/delete-subcategory`, {
        headers: {
          Authorization: `Bearer ${getCurrentUser()?.token}`,
        },
        data: payload,
      });
      getSubCategoryData();
      handleDeleteCloseConfirmModal();
      setSubmitLoading(false);
    } catch (error) {
      console.error("Error deleting subcategory:", error);
      setSubmitLoading(false);
    }
  };

  // edit sub category event

  const handleEditSubCategoryData = async (editRowData) => {
    setEditedId(editRowData.id);
    setCategoryName(editRowData.label);
    setOpenModal(true);
    // edit and add sub category data
    setIcon(editRowData.icon);
  };
  const handleSubmit = async (e) => {
    setSubmitLoading(true);
    e.preventDefault();
    if (editedId) {
      try {
        const formData = new FormData();
        icon && typeof icon !== "string" && formData.append("icon", icon);
        icon && formData.append("isIconNull", null);
        formData.append("isIconNull", 1);
        formData.append("subCategoryId", editedId);
        formData.append("subCategoryName", categoryName);
        formData.append("app_id", id);
        formData.append("categoryId", subId);
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        };
        const res = await axios.patch(
          `${baseURL}admin/update-subcategory`,
          formData,
          config
        );
        if (res?.data?.success === true) {
          handleCloseModal();
          setEditedId(null);
          successNotification(res.data?.message);
          getSubCategoryData();
          setSubmitLoading(false);
          setCategoryName("");
          setIcon("");
        } else {
          errorNotification(res.data.errormessage);
          setSubmitLoading(false);
        }
        setSubmitLoading(false);
      } catch (error) {
        console.log(error);
        setSubmitLoading(false);
      }
    } else {
      try {
        const formData = new FormData();
        // icon && formData.append("icon", icon);
        formData.append("subCategoryName", categoryName);
        formData.append("categoryId", subId);
        formData.append("app_id", id);
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        };
        const res = await axios.post(
          `${baseURL}admin/add-subcategory`,
          formData,
          config
        );

        if (res?.data?.success === true) {
          successNotification(res?.response?.data?.message);
          setEditedId(null);
          handleCloseModal();
          getSubCategoryData();
          setCategoryName("");
          getSubCategoryData();
          setSubmitLoading(false);
          setIcon("");
        } else {
          errorNotification(res.errormessage);
          setSubmitLoading(false);
        }
        setSubmitLoading(false);
      } catch (error) {
        console.log(error);
        setSubmitLoading(false);
      }
    }
  };

  // close modal

  const handleCloseModal = () => {
    setEditedId(null);
    setOpenModal(false);
    setCategoryName("");
    setIcon("");
  };

  // open modal

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  // delete icon click event

  const handleDeleteModal = (row) => {
    setModalSubCategoryName(row.label);
    setDeleteModalTableId(row.id);
    setDeleteModal(true);
  };

  // delete close confirm modal

  const handleDeleteCloseConfirmModal = () => {
    setDeleteModal(false);
  };

  // open csv modal

  const handleOpenCsvModal = () => {
    setCsvModal(true);
  };

  // close csv modal

  const handleClose = () => {
    setCsvModal(false);
    setCSVData([]);
  };

  // csv file target

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setCsvFile(file);
    Papa.parse(file, {
      complete: (result) => {
        setCSVData(result.data);
      },
      header: true,
    });
  };

  // upload csv file

  const uploadCsvFile = async () => {
    setSubmitLoading(true);
    try {
      const formData = new FormData();
      formData.append("app_id", id);
      formData.append("categoryId", subId);
      formData.append("file", csvFile);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCurrentUser()?.token}`,
        },
      };
      const response = await axios.post(
        `${baseURL}admin/add-subcategory-csv`,
        formData,
        config
      );
      if (response?.data?.success === true) {
        successNotification(response.data?.message);
        setCsvModal(false);
        setSubmitLoading(false);
        getSubCategoryData();
      } else {
        errorNotification(response.data.errormessage);
        setSubmitLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    setCSVData([]);
  };

  // image remove event

  const handleRemoveIcon = () => {
    setIcon("");
  };

  // image select

  const handleSelectIcon = (e) => {
    setIcon(e.target.files[0]);
  };

  return (
    <>
      <Paper elevation={0} sx={style.mainPadding}>
        <Box sx={style.headerPosition}>
          <Typography
            sx={style.appHeaderName}
            display="flex"
            alignItems="center"
          >
            <Typography sx={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
              <img src={backArrow} />
            </Typography>
            <Typography px={2}>{subCategoryTitle}</Typography>
          </Typography>
          <Box>
            <Button
              variant="outlined"
              sx={style.addBtnStyleOne}
              onClick={handleOpenModal}
            >
              ADD
            </Button>
            <Button
              variant="outlined"
              sx={style.addBtnStyleOne}
              onClick={handleOpenCsvModal}
            >
              ADD CSV
            </Button>
          </Box>
        </Box>
        <Divider sx={style.headerLine} />
        <Box sx={style.bodyStyleHeight} className="tableScrollNone">
          {loading ? (
            <Box sx={style.loaderPosition}>
              <CircularProgress sx={{ color: "#E75126" }} />
            </Box>
          ) : categoryNameData.length === 0 ? (
            <Box sx={style.noDataFoundText}>
              <Typography sx={style.noDataFound}>No Data</Typography>
            </Box>
          ) : (
            <Box sx={style.mainPart}>
              <Paper variant="outlined">
                <TableContainer
                  sx={style.categoryBoxStyle}
                  className="tableScrollNone"
                >
                  <Table stickyHeader={true} aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={style.tableHeaderTextColor}>
                          No.
                        </TableCell>
                        <TableCell sx={style.tableHeaderTextColor}>
                          Sub Category
                        </TableCell>
                        <TableCell sx={style.tableHeaderTextColor}>
                          Icon
                        </TableCell>
                        <TableCell
                          sx={style.tableHeaderTextColor}
                          align="right"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {categoryNameData.map((subCategory, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={style.categoryFontStyle}
                          >
                            {index + 1}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            sx={style.categoryFontStyle}
                          >
                            {subCategory.label}
                          </TableCell>
                          {subCategory.icon ? (
                            <TableCell
                              component="th"
                              scope="row"
                              sx={style.categoryFontStyle}
                            >
                              <img
                                src={subCategory.icon}
                                height="25px"
                                width="25px"
                              ></img>
                            </TableCell>
                          ) : (
                            <TableCell
                              component="th"
                              scope="row"
                              sx={style.categoryFontStyle}
                            ></TableCell>
                          )}
                          <TableCell align="right">
                            <Typography
                              display="flex"
                              justifyContent="end"
                              sx={style.categoryFontStyleCursor}
                            >
                              <img
                                src={editIcon}
                                onClick={() =>
                                  handleEditSubCategoryData(subCategory)
                                }
                              />
                              <Typography pl={3}>
                                <img
                                  src={deleteIcon}
                                  onClick={() => handleDeleteModal(subCategory)}
                                />
                              </Typography>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          )}
        </Box>
        <ConfirmationDeleteModal
          handleDeleteCloseConfirmModal={handleDeleteCloseConfirmModal}
          deleteModal={deleteModal}
          handleDeleteApiCall={handleDeleteSubCategory}
          text={`Are you sure delete this "${modalSubCategoryName}" sub category?`}
          submitLoading={submitLoading}
        />
      </Paper>

      {/* modal  */}

      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style.modal}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              sx={style.modalMainPart}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box item sx={style.modalHeaderStyle}>
                {editedId ? "Update" : "Add"} Sub Category
              </Box>
              <Box item>
                <RiCloseFill
                  onClick={() => handleCloseModal()}
                  size="22"
                  cursor="pointer"
                />
              </Box>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <InputField
                  type="text"
                  required={true}
                  value={categoryName}
                  placeholder="Enter Sub Category"
                  onChange={(e) => {
                    const inputText = e.target.value;
                    const capitalizedText =
                      inputText.charAt(0).toUpperCase() + inputText.slice(1);
                    setCategoryName(capitalizedText);
                  }}
                />
              </Grid>
              {editedId && (
                <Grid item xs={12} sx={{ display: "flex" }}>
                  {!icon && (
                    <Button
                      variant="contained"
                      component="label"
                      type="button"
                      sx={{
                        backgroundColor: "#E75126",
                        padding: "6px 25px",
                        textTransform: "capitalize",
                        fontSize: "18px",
                        borderRadius: "10px",
                        "&:hover": {
                          bgcolor: "#E75126",
                        },
                      }}
                    >
                      Select Image
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={(e) => handleSelectIcon(e)}
                      />
                    </Button>
                  )}
                  {icon && (
                    <Box sx={{ position: "relative" }}>
                      {typeof icon === "string" ? (
                        <>
                          <img
                            style={{ marginLeft: "20px" }}
                            src={icon}
                            alt="notFound"
                            height={40}
                            width={40}
                          />
                        </>
                      ) : (
                        <img
                          style={{ marginLeft: "20px" }}
                          src={URL.createObjectURL(icon)}
                          alt="notFound"
                          height={40}
                          width={40}
                        />
                      )}
                      <div
                        style={style.subCategoryRemoveIcon}
                        onClick={() => handleRemoveIcon()}
                      >
                        <AiOutlineClose style={{ fontSize: "12px" }} />
                      </div>
                    </Box>
                  )}
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={style.addBtnStyle}
                mb={2}
                mt={2}
                display="flex"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={style.submitBtnStyle}
                  disabled={submitLoading}
                >
                  {submitLoading && (
                    <CircularProgress
                      size={16}
                      sx={{ marginRight: "10px", color: "#E75126" }}
                    />
                  )}
                  {editedId ? "Save" : "Add"}
                </Button>
                <Button
                  variant="outlined"
                  sx={style.cancelBtn}
                  onClick={() => handleCloseModal()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>

      {/* csv file upload modal  */}

      <Modal
        open={csvModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style.csvModal}>
          <Grid
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <label htmlFor="csv-file-input">
              <Button
                component="span"
                variant="contained"
                sx={style.csvBtnStyle}
              >
                Choose CSV File
              </Button>
            </label>
            <Box item xs={2} textAlign="end">
              <RiCloseFill
                onClick={() => handleClose()}
                size="22"
                cursor="pointer"
              />
            </Box>
          </Grid>
          <input
            type="file"
            accept=".csv"
            id="csv-file-input"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          {csvData.length > 0 && (
            <Box
              my={csvData.length > 0 && 2}
              sx={style.dataOverFlow}
              className="tableScrollNone"
            >
              <Paper elevation={0} variant="outlined">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {Object.keys(csvData[0]).map((header) => (
                          <TableCell sx={{ fontSize: "16px" }} key={header}>
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {csvData.map((row, index) => (
                        <TableRow key={index}>
                          {Object.values(row).map((cell, cellIndex) => (
                            <TableCell key={cellIndex}>{cell}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          )}
          {csvData.length !== 0 && (
            <Button
              onClick={uploadCsvFile}
              variant="contained"
              sx={style.csvBtnStyle}
              disabled={submitLoading}
            >
              {submitLoading && (
                <CircularProgress
                  size={16}
                  sx={{ marginRight: "10px", color: "#E75126" }}
                />
              )}
              Upload
            </Button>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default SubCategory;
