import { useNavigate } from "react-router-dom";
import { createContext, useContext, useEffect, useState } from "react";

export const ThemeContext = createContext();

export function useGlobalContext() {
  return useContext(ThemeContext);
}

export const ThemeContextProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem("current_user") || "");

  const [isLogin, setIsLogin] = useState(Boolean(user));

  const navigate = useNavigate();

  // Logout
  const logout = () => {
    localStorage.removeItem("current_user");
    setIsLogin(false);
    navigate("/login");
  };

  // Use Effects
  useEffect(() => {
    setIsLogin(Boolean(user));
  }, [user]);

  const contextValue = {
    user,
    logout,
    setUser,
    isLogin,
    setIsLogin,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};
