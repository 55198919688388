import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import InputField from "../../comman/component/CustomInput";
import { postRequest } from "../../../helper/AxiosClient";

const CompanyModal = ({ open, close, refrehListing }) => {
  const [amount, setAmount] = useState("");
  const [amountErr, setAmountErr] = useState("");

  const [loading, setLoading] = useState(false);

  const handleWithdraw = async (e) => {
    e.preventDefault();

    if (amount === "") {
      return setAmountErr("Required");
    } else if (isNaN(amount) || Number(amount) <= 0) {
      return setAmountErr("Value should be greater than 0");
    } else {
      let payload = {
        currency: open?.data,
        amount: amount?.toString(),
      };

      setLoading(true);

      try {
        const res = await postRequest("payment/admin-withdraw", payload);

        if (res.type === 1) {
          if (res?.response?.data?.success === true) {
            close();
            emptyFields();
            setLoading(false);
            refrehListing();
          }
        } else if (res.type === 2) {
          setAmountErr(res?.errormessage);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        close();
        emptyFields();
        setLoading(false);
        refrehListing();
      }
    }
  };

  const emptyFields = () => {
    setAmount("");
    setAmountErr("");
    setLoading(false);
  };

  useEffect(() => {
    emptyFields();
  }, [open]);

  return (
    <div>
      <Modal
        open={open?.open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4>Withdraw</h4>
            <Typography
              sx={{ color: "red", cursor: "pointer" }}
              onClick={close}
            >
              <CloseIcon />
            </Typography>
          </div>

          <InputLabel className="mb-2">
            Amount
            <span className="text-danger">
              * <small>{amountErr && amountErr} </small>{" "}
            </span>
          </InputLabel>
          <InputField
            id="amount"
            name="amount"
            type="amount"
            placeholder="Enter Amount"
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
              setAmountErr("");
            }}
          />

          <div className="d-flex justify-content-end mt-4">
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{ width: "100%" }}
              onClick={handleWithdraw}
            >
              {loading && (
                <CircularProgress size="1rem" sx={{ marginRight: "10px" }} />
              )}
              Withdraw
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CompanyModal;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,

  bgcolor: "background.paper",
  boxShadow: 24,
  px: 2,
  py: 2,
};
