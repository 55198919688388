import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputField from "../../comman/component/CustomInput";
import KeyValuePair from "./KeyValuePair";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../helper/AxiosClient";
import ViewAllModal from "./ViewAllModal";
import { ToastContainer, toast } from "react-toastify";
import ReactQuill from "react-quill";

const EmailInvitationModal = ({ handleClose, open, getpreSignupUsers }) => {
  const [toErr, setToErr] = useState("");

  const [subject, setSubject] = useState("");
  const [subjectErr, setSubjectErr] = useState("");

  const [message, setMessage] = useState("");

  const [messageErr, setMessageErr] = useState("");

  const [loading, setLoading] = useState(false);

  const [allUsers, setAllUsers] = useState([]);

  const [viewAll, setViewAll] = useState(false);

  // Validation
  const isValidForm = () => {
    let isValidData = true;

    if (subject === "") {
      setSubjectErr("Required");
      isValidData = false;
    }

    if (!message || message.replace(/<(.|\n)*?>/g, "").trim() === "") {
      setMessageErr("Required");
      isValidData = false;
    }

    if (allUsers.length === 0) {
      setToErr("Required");
      isValidData = false;
    }

    return isValidData;
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      app_id: 2,
      emails: allUsers,
      subject: subject,
      content: message,
    };

    if (isValidForm()) {
      setLoading(true);
      const res = await postRequest("admin/send-password-email", payload);
      if (res.response.status === 200) {
        handleClose();
        clearAllData();
        setLoading(false);
        getpreSignupUsers();
        toast.success("Email Sent Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setLoading(false);
      }
    }
  };

  const clearAllData = () => {
    setSubject("");
    setMessage("");

    setAllUsers([]);

    setToErr("");
    setSubjectErr("");
    setMessageErr("");

    setLoading(false);
  };

  const getUsers = async () => {
    try {
      const res = await getRequest("admin/get-unsent-request-users");
      let arr = [];
      res.data.data.map((item) => {
        arr.push(item.email);
      });
      setAllUsers(arr);
    } catch (error) {}
  };

  const getEmailContent = async () => {
    const res = await getRequest("admin/send-email-templates");
    if (res.status === 200) {
      setSubject(res.data.data?.subject);
      setMessage(res.data.data?.content);
    }
  };

  // Use States
  useEffect(() => {
    getUsers();
    getEmailContent();
  }, [handleClose, open]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Send email to selected users
          </Typography>

          <div className="d-flex">
            <h6>To</h6>
            <span className="text-red h5">*</span>
            {toErr && (
              <span className="text-danger error-text ps-2">{toErr}</span>
            )}
          </div>
          {allUsers.slice(0, 6).map((email) => (
            <KeyValuePair
              value={email}
              onRemove={() => {
                // Update the state to remove the email
                setAllUsers(
                  allUsers.filter((userEmail) => userEmail !== email)
                );
              }}
            />
          ))}
          {allUsers.length > 6 && (
            <span className="view_all" onClick={() => setViewAll(true)}>
              View All
            </span>
          )}

          <form onSubmit={handleSubmit}>
            <div className="overflow_email_invi_modal">
              <Box mb={2} mt={2}>
                <div className="d-flex">
                  <h6 className="mb-2">Subject</h6>
                  <span className="text-red h5">*</span>
                  {subjectErr && (
                    <span className="text-danger error-text ps-2">
                      {subjectErr}
                    </span>
                  )}
                </div>
                <InputField
                  type="text"
                  placeholder="Enter Subject"
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
                    setSubjectErr("");
                  }}
                  isLoginPage={true}
                />
              </Box>

              <Box mb={2} mt={2}>
                <div className="d-flex">
                  <h6 className="mb-2">Message</h6>
                  <span className="text-red h5">*</span>
                  {messageErr && (
                    <span className="text-danger error-text ps-2">
                      {messageErr}
                    </span>
                  )}
                </div>

                <div className="w-100">
                  <ReactQuill
                    value={message}
                    onChange={(e) => {
                      setMessage(e);
                      setMessageErr("");
                    }}
                    placeholder="Enter Message"
                    style={{ height: "150px" }}
                  />
                </div>
              </Box>
            </div>

            <div className="d-flex justify-content-end mt-3">
              <button
                className="btn btn-danger me-3"
                onClick={() => {
                  handleClose();
                  clearAllData();
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-success"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <div
                      class="spinner-border spinner-border-sm"
                      role="status"
                    ></div>{" "}
                    Submit
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </Box>
      </Modal>

      <ToastContainer />

      <ViewAllModal
        open={viewAll}
        close={() => {
          setViewAll(false);
        }}
        allUsers={allUsers}
        setAllUsers={setAllUsers}
      />
    </>
  );
};

export default EmailInvitationModal;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
