import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

const KeyValuePair = ({ value, onRemove }) => {
  return (
    <>
      <div className="d-inline-flex align-items-center key_value_parent me-2 mb-2">
        <p className="mb-0" style={{ fontSize: "13px" }}>
          {value}
        </p>
        <Typography sx={{ cursor: "pointer", color: "red" }} onClick={onRemove}>
          <CloseIcon sx={{ fontSize: "18px" }} />
        </Typography>
      </div>
    </>
  );
};

export default KeyValuePair;
