import { Box, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import KeyValuePair from "./KeyValuePair";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";

const ViewAllModal = ({ open, close, allUsers, setAllUsers }) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Event handler for search input changes
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter users based on search term
  const filteredUsers = allUsers?.filter((email) =>
    email?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  useEffect(() => {
    setSearchTerm("");
  }, [open, close, allUsers]);

  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h6>All Users</h6>
            <Typography
              sx={{ color: "red", cursor: "pointer" }}
              onClick={close}
            >
              <CloseIcon />
            </Typography>
          </div>
          <div className="w-100">
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className="view_all_height">
            {filteredUsers.length > 0 ? (
              filteredUsers.map((email) => (
                <KeyValuePair
                  key={email}
                  value={email}
                  onRemove={() => {
                    setAllUsers(
                      allUsers?.filter((userEmail) => userEmail !== email)
                    );
                  }}
                />
              ))
            ) : searchTerm ? (
              <div className="text-center">No results found</div>
            ) : null}
          </div>
          <div className="d-flex justify-content-end">
            <button className="btn btn-secondary px-4" onClick={close}>
              Save
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ViewAllModal;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,

  bgcolor: "background.paper",
  boxShadow: 24,
  px: 2,
  py: 2,
};
