import {
  Box,
  Button,
  InputAdornment,
  InputBase,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import downArrow from "../../../assets/images/Vector.png";
import userImage from "../../../assets/images/Ellipse 10.png";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { alpha, styled } from "@mui/material/styles";
import { setLocalStorageItem } from "../../../helper/Utils";
import notificationIcon from "../../../assets/images/notification.png";
import { getCurrentUser } from "../../../helper/AxiosClient";
import { style } from "./HeaderCss";
import { useGlobalContext } from "../../../context/GlobalContext";

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "50px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const Header = ({ drawerWidth, mobileOpen, setMobileOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useGlobalContext();
  const [userDropDown, setUserDropDown] = useState(null);
  const openUser = Boolean(userDropDown);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleUserClick = (event) => {
    setUserDropDown(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleUserClose = () => {
    setUserDropDown(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 3,
          display: { sm: "none" },
        },
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        sx={[
          {
            "&:hover": { backgroundColor: "white" },
            backgroundColor: "white",
            paddingBottom: "10px",
          },
        ]}
      >
        <IconButton
          sx={{ p: 0, marginLeft: 2, pt: 1 }}
          aria-haspopup="true"
          aria-expanded={openUser ? "true" : undefined}
          onClick={handleUserClick}
        >
          <Avatar
            alt="Rem Sharp"
            src="/static/images/avatar/2.jpg"
            sx={{ width: "35px", height: "35px" }}
          />
        </IconButton>
        <Typography sx={{ color: "black", pl: 3, fontSize: "18px" }}>
          Profile
        </Typography>
      </MenuItem>
    </Menu>
  );

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: "12px",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
    },
  }));
  const webStyle = {
    header_search: {
      border: "1.4px solid #D2D2D2",
      backgroundColor: "white",
      color: "black",
      "&:hover": {
        backgroundColor: "white",
        color: "black",
      },
      "&:focused": {
        backgroundColor: "white",
        color: "black",
      },
    },
  };

  return (
    <>
      {/* <img src={notificationIcon}></img> */}
      {/* <img src={userImage} alt="not found" /> */}
      <AppBar
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          backgroundColor: "white",
          position: "fixed",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Box sx={{ mr: 2, display: { md: "none" } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <GiHamburgerMenu style={{ color: "black" }} />
            </IconButton>
          </Box>

          <Box display={{ sm: "flex", xs: "none" }} py={1}>
            {/* <Box
              sx={{
                paddingRight: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={notificationIcon}></img>
            </Box> */}
            {/* <Tooltip title="Open settings"> */}
            <IconButton
              sx={{ p: 0, borderRadius: 0 }}
              aria-haspopup="true"
              aria-expanded={openUser ? "true" : undefined}
              onClick={handleUserClick}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "start",
                }}
                px={1}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  textAlign="start"
                >
                  <Typography px={2} color="#212434">
                    {getCurrentUser()?.name}
                  </Typography>

                  <img
                    src={downArrow}
                    color="#212434"
                    sx={{ opacity: "50%" }}
                    alt="downArrow"
                  />
                </Box>
              </Box>
            </IconButton>
            {/* </Tooltip> */}

            <Menu
              Menu
              anchorEl={userDropDown}
              id="account-menu"
              open={openUser}
              onClose={handleUserClose}
              onClick={handleUserClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={handleUserClose}
                display="flex"
                flexDirection="column"
              >
                {/* <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography px={1}>{getCurrentUser()?.name}</Typography>
                </Box> */}
                {/* <Link to={"/"} onClick={handleLogout}> */}
                <Button
                  sx={style.addBtnStyleOne}
                  variant="outlined"
                  onClick={logout}
                >
                  <Typography color="#E75126" fontSize="16px">
                    Sign Out
                  </Typography>
                </Button>
                {/* </Link> */}
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ mr: 2, display: { sm: "none" } }}>
            <IconButton
              aria-label="open drawer"
              edge="start"
              color="black"
              onClick={handleMobileMenuOpen}
            >
              <BiDotsVerticalRounded style={{ color: "black" }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  );
};
export default Header;
