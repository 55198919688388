import { InputBase, Typography } from "@mui/material";
import styled from "styled-components";
import { style } from "../../../css/comman";

const CustomInput = styled(InputBase)(({ isLoginPage }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid #DCDCDC",
    fontSize: 16,
    color: "black",
    padding: "8px 10px",
    WebkitTextFillColor: "black",
    "&:focus": {
      borderColor: isLoginPage ? "#0075FF" : "#E75126",
    },
  },
}));

const InputField = ({
  label,
  placeholder,
  required = false,
  value,
  name,
  onChange,
  maxLength,
  errorMessage,
  type,
  disabled,
  isLoginPage = false,
}) => {
  return (
    <>
      <Typography sx={style.inputLabel} mb={1}>
        {label}
      </Typography>
      <CustomInput
        placeholder={placeholder}
        value={value}
        name={name}
        autoComplete="off"
        onChange={onChange}
        inputProps={{ maxLength: maxLength }}
        type={type}
        disabled={disabled}
        required={required}
        isLoginPage={isLoginPage}
      />
      {errorMessage}
    </>
  );
};

export default InputField;
