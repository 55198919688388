import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import React, { useState } from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
import { AiOutlineAppstore } from "react-icons/ai";
import { PiUsersLight } from "react-icons/pi";
import { BiCategoryAlt } from "react-icons/bi";
// import { HiShoppingCart } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import Header from "../header/Header";
import { getCurrentUser } from "../../../helper/Utils";
import { MdLanguage } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import appIcon from "../../../assets/images/appIcon.png";
import { Payment } from "@mui/icons-material";

import logo from "../../../assets/images/logo_ship2mates.svg";

const drawerWidth = 245;
const subAdminNotShow = JSON.parse(localStorage.getItem("current_user"));

const Sidebar = (props) => {
  const ListItems = [
    // {
    //   icon: <BiCategoryAlt />,
    //   text: "Post Category",
    //   to: `/postCategory`,
    //   show: true,
    // },
    {
      icon: <AiOutlineAppstore />,
      text: "Apps",
      to: `/apps`,
      show: getCurrentUser()?.sub_admin === false,
    },
    // {
    //   icon: <img src={appIcon} />,
    //   text: "Apps",
    //   to: `/apps`,
    //   show: getCurrentUser()?.sub_admin === true,
    // },
    // {
    //   icon: <AiOutlineBars />,
    //   text: "CategoryTwo",
    //   to: `/category`,
    //   show: true,
    // },
    // {
    //   icon: <AiOutlineBars />,
    //   text: "SubCategory",
    //   to: `/category`,
    //   show: true,
    // },
    {
      icon: <AiOutlineUserAdd />,
      text: "Sub Admin",
      to: `/subAdmin`,
      show: getCurrentUser()?.sub_admin === false,
    },
    {
      icon: <PiUsersLight />,
      text: "Users",
      to: `/appsUsers`,
      show: getCurrentUser()?.sub_admin === false,
    },

    {
      icon: <PiUsersLight />,
      text: "Pre Signup Users",
      to: `/pre-signup-users`,
      show: getCurrentUser()?.sub_admin === true,
    },
    {
      icon: <Payment />,
      text: "Payments",
      to: `/payments`,
      show: getCurrentUser()?.sub_admin === true,
    },
    // {
    //   icon: <MdLanguage />,
    //   text: "Prefered Languages",
    //   to: `/preferedLanguages`,
    //   show: getCurrentUser()?.sub_admin === true,
    // },
    // {
    //   icon: <CgProfile />,
    //   text: "Profile Types",
    //   to: `/profiletypes`,
    //   show: true,
    // },
  ];
  const [mobileOpen, setMobileOpen] = useState(false);
  const { pathname } = useLocation();
  const { window } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const handleDrawerToggle = (to) => {
    setMobileOpen(to === undefined ? mobileOpen : !mobileOpen);
  };

  const data = ListItems;
  const drawer = (
    <div style={{ backgroundColor: "white" }}>
      <Toolbar sx={{ pt: 2, pb: 3, fontSize: "30px", fontWeight: "700" }}>
        <img src={logo} className="w-75" />
      </Toolbar>
      <List sx={{ paddingLeft: "15px" }}>
        {data.map(({ text, to, icon, show }, index) => (
          <React.Fragment key={index}>
            {show && (
              <ListItem
                to={to}
                component={Link}
                disablePadding
                onClick={() => {
                  handleDrawerToggle(to);
                }}
                style={
                  pathname.split("/").includes(to.split("/")[1])
                    ? {
                        backgroundColor: "#fcf1eb",
                        color: "#000",
                      }
                    : { color: "#ACACAC" }
                }
              >
                {pathname.split("/").includes(to.split("/")[1]) ? (
                  <Typography
                    sx={{
                      borderLeft: "10px solid  #E75126;",
                      height: "51px",
                    }}
                  ></Typography>
                ) : (
                  <Typography
                    sx={{
                      borderLeft: "10px solid  white",
                      height: "51px",
                    }}
                  ></Typography>
                )}
                <ListItemButton>
                  <ListItemIcon
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "20px",
                      color: `${
                        pathname.split("/").includes(to.split("/")[1])
                          ? "black"
                          : ""
                      }`,
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={
                          pathname.split("/").includes(to.split("/")[1])
                            ? {
                                fontSize: "14px",
                                fontWeight: "600",
                              }
                            : { fontSize: "14px" }
                        }
                      >
                        {text}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            )}
          </React.Fragment>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header
          drawerWidth={drawerWidth}
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
        />
        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "white",
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "white",
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
