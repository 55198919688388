import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../layout/sidebar/Sidebar";

export const Layout = ({ hidePaths = [] }) => {
  const { pathname } = useLocation();
  return (
    <>
      {!hidePaths.includes(pathname) && <Sidebar />}
      <Outlet />
    </>
  );
};
