import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { postRequest } from "../../../helper/AxiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import { setLocalStorageItem } from "../../../helper/Utils";
import {
  Box,
  Grid,
  Paper,
  Typography,
  InputLabel,
  Button,
  CircularProgress,
} from "@mui/material";
import InputField from "../../comman/component/CustomInput";
import { style } from "./LoginCss";
import { useGlobalContext } from "../../../context/GlobalContext";

const Login = () => {
  const [loginData, setloginData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { setIsLogin } = useGlobalContext();

  // login api integration

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await postRequest("auth/login-admin", loginData);
      if (res.type === 1) {
        setLocalStorageItem(
          "current_user",
          JSON.stringify(res?.response?.data?.data)
        );
        setIsLogin(true);
        navigate("/apps");
        successNotification(res?.response?.data?.message);
        setLoading(false);
      }
      if (res.type === 2) {
        errorNotification(res.errormessage);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // input handleChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setloginData({ ...loginData, [name]: value });
  };

  return (
    <>
      <Paper elevation={0} sx={{ height: "100vh" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={style.loginImage}></Box>
          </Grid>
          <Grid item xs={6} sx={style.rightContainer}>
            <Box sx={style.loginForm}>
              <Typography variant="h4">Login</Typography>
              <Typography>Welcome back, Please enter your details.</Typography>
              <form onSubmit={handleLogin}>
                <Box mb={3} mt={3}>
                  <InputLabel>Email address</InputLabel>
                  <InputField
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter Email"
                    value={loginData.email}
                    onChange={handleInputChange}
                    required
                    isLoginPage={true}
                  />
                </Box>
                <Box mb={3}>
                  <InputLabel>Password</InputLabel>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={style.passwordWrapper}
                  >
                    <InputField
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      onChange={handleInputChange}
                      value={loginData.password}
                      placeholder="Enter Password"
                      autoComplete="false"
                      required
                      isLoginPage={true}
                    />

                    <Typography
                      sx={style.eyeIcons}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={style.loginButton} mt={5}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    sx={{ width: "100%" }}
                  >
                    {loading && (
                      <CircularProgress
                        size="1rem"
                        sx={{ marginRight: "10px" }}
                      />
                    )}
                    Login
                  </Button>
                  {/* <Typography textAlign="center" marginTop="2rem">
                    Don't have an account?{" "}
                    <Link to="/register">
                      <u>Register now</u>
                    </Link>
                  </Typography> */}
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Login;
